import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import SignOut from "./common/components/userAuth/SignOut";
import UserAuthPage from "./common/components/userAuth/UserAuthPage";
import Redirect from "./common/components/Redirect/Redirect";
import logoPlaceholder from "./assets/img/cm-logo.png";
import PortalContainer from "./components/PortalContainer/PortalContainer";
// import InventoryBrowser from "./components/InventoryBrowser/InventoryBrowser";
import InventoryUploadReview from "./components/InventoryUploadReview/InventoryUploadReview";
import InventorySearch from "./components/InventorySearch/InventorySearch";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: "signup",
    element: <UserAuthPage kind="signup" logoImg={logoPlaceholder} />,
  },
  {
    path: "signin",
    element: <UserAuthPage kind="signin" logoImg={logoPlaceholder} />,
  },
  {
    path: "signout",
    element: <SignOut />,
  },
  {
    path: "/",
    element: <PortalContainer />,
    children: [
      {
        path: "inventory",
        // element: <InventoryBrowser />,
        // TODO combine browse and search somehow.
        element: <InventorySearch />,
      },
      {
        path: "review",
        element: <InventoryUploadReview />,
      },
      {
        path: "",
        element: <Redirect to="/inventory" />,
      },
      {
        path: "*",
        element: <>Page not found</>,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
