import "./WedgeLoader.css";

export default function WedgeLoader() {
  return (
    <>
      <div className="loadingio-spinner-wedges-12o9fmbolap">
        <div className="ldio-7g7yxc3r4pv">
          <div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
