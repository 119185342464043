import {
  ClipboardDocumentListIcon,
  BanknotesIcon,
  BuildingOfficeIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as ShapesIcon } from "../../assets/img/shapes.svg";
import { ReactComponent as DimensionsIcon } from "../../assets/img/dimensions.svg";
import { Product } from "../../common/models/product";
import { convertDimensionUnits } from "./helpers";

const SearchResultsGrid = ({
  products,
  dimensionUnit,
}: {
  products: Product[];
  dimensionUnit: string;
}) => {
  const formatPrice = (product: Product): string => {
    if (product.price === undefined || product.price === null) return "-";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: product.price.currency,
    });

    return `${formatter.format(product.price.value)} per ${product.price.unit}`;
  };

  const formatDimensions = (product: Product, unit: string) => {
    if (!product.dimensions) return "-";

    return (
      Object.entries(product.dimensions)
        .map(([label, d]) => {
          const fromUnit = d.unit || "ft";
          const numDecimals = unit === "mm" ? 0 : 2;
          return convertDimensionUnits(fromUnit, unit, d.value).toFixed(
            numDecimals,
          );
        })
        .join(" x ") +
      " " +
      unit
    );
  };

  const sourceCategoryBadge = (product: Product) => {
    if (product.sourceCategory === "scraping") {
      return (
        <span className="bg-amber-500 text-gray-200 text-xs px-2 py-1 rounded-full inline-block">
          scraped
        </span>
      );
    }
    return (
      <span className="bg-blue-500 text-gray-200 text-xs px-2 py-1 rounded-full inline-block">
        uploaded
      </span>
    );
  };

  const extractMaterialString = (p: Product) => {
    if (typeof p.material === "string")
      return (
        <>
          {p.material} {p.alloy} {p.grade}
        </>
      );
    return Object.values(p.material).join(" ");
  };

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 mx-2 lg:mx-0">
      {products.map((p, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] border border-gray-200 rounded-lg p-5 shadow-md flex flex-col justify-between"
        >
          <h3 className="font-semibold mb-7">{p.name}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm">
            <div className="group flex gap-2">
              <ClipboardDocumentListIcon className="w-5 h-5 shrink-0" />
              {extractMaterialString(p)}
            </div>
            <div className="group flex gap-2">
              <ShapesIcon className="w-5 h-5 shrink-0" />
              {p.shape}
            </div>
            <div className="group flex gap-2">
              <BanknotesIcon className="w-5 h-5 shrink-0" />
              {formatPrice(p)}
            </div>
            <div className="group flex gap-2">
              <DimensionsIcon className="w-5 h-5 shrink-0" />
              {formatDimensions(p, dimensionUnit)}
            </div>
            <div className="group flex gap-2">
              <BuildingOfficeIcon className="w-5 h-5 shrink-0" />
              {p.supplierName || "Unknown"}
            </div>
            {p.url && p.url.startsWith("http") && (
              <div className="group flex gap-2">
                <LinkIcon className="w-5 h-5 shrink-0" />
                <a
                  href={p.url}
                  className="border-b border-dashed border-black"
                  target="_blank"
                >
                  Link
                </a>
              </div>
            )}
          </div>
          <div className="mt-5">{sourceCategoryBadge(p)}</div>
        </div>
      ))}
    </div>
  );
};

export default SearchResultsGrid;
