import { useState, useEffect } from "react";
import { firebaseAuth } from "../../firebase";
import { User as FirebaseUser } from "firebase/auth";

const useFirebaseAuthentication = () => {
  const [authUser, setAuthUser] = useState<FirebaseUser | null>(null);
  const [authIsLoading, setAuthIsLoading] = useState(true);

  useEffect(() => {
    const unlisten = firebaseAuth.onAuthStateChanged((authUser) => {
      setAuthUser(authUser);
      setAuthIsLoading(false);
    });
    return () => {
      unlisten();
    };
  }, []);

  return { authUser, authIsLoading };
};

export default useFirebaseAuthentication;
