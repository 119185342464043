import "./MagnifyLoader.css";

export default function MagnifyLoader() {
  return (
    <div className="loadingio-spinner-magnify-h3t926es89l">
      <div className="ldio-jxlpd2uwat">
        <div>
          <div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
