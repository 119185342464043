import { useEffect, useState } from "react";
import { firebaseAuth } from "../../../firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import useFirebaseAuthentication from "../../hooks/useFirebaseAuthentication";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import { ReactComponent as GoogleIcon } from "../../assets/img/google-g.svg";

export default function UserAuthPage({ kind, logoImg }: UserAuthPageProps) {
  const [ssoErrorCode, setSsoErrorCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { authUser, authIsLoading } = useFirebaseAuthentication();

  const handleGoogleSignin = () => {
    firebaseAuth.useDeviceLanguage();
    const provider = new GoogleAuthProvider();
    signInWithPopup(firebaseAuth, provider).catch((error) => {
      console.error(error);
      setSsoErrorCode(error.code);
    });
  };

  useEffect(() => {
    if (!!authUser) {
      const redirectPath = decodeURIComponent(
        new URLSearchParams(location.search).get("redirect") || "",
      );
      navigate(redirectPath || "/");
    }
  }, [authUser]);

  return (
    <div className="mx-auto md:w-[30em] px-4 py-10">
      <img src={logoImg} className="mx-auto mb-10 w-auto h-[7em]" />
      <div
        className="h-10 bg-slate-800 block w-full rounded text-[#f1f5f9] disabled:bg-[#88aab3] min-w-[15em] flex gap-0 cursor-pointer"
        onClick={handleGoogleSignin}
      >
        <div className="w-10 h-10 flex-none">
          <GoogleIcon className="w-8 h-8 pt-2 pl-2" />
        </div>
        <div className="flex-1 align-middle text-center mt-[7px]">
          Sign in with Google
        </div>
      </div>

      <div className="text-center my-4">or</div>

      {kind == "signin" ? <SignInForm /> : <SignUpForm />}
    </div>
  );
}

type UserAuthPageProps = {
  kind: string;
  logoImg: string;
};
