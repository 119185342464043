import { sortBy, sum } from "lodash";
import { normalizeDimensionToMm } from "./helpers";

// The material and grade extraction logic is a bit of a workaround for the
// fact that we changed the material schema of the inventory items and not all
// items have been updated yet.
// We have some further material schema changes coming, then this should be
// cleaned up.
const extractMaterial = (searchResult: any): string => {
  if (!searchResult.material) return "";
  if (typeof searchResult.material === "string") return searchResult.material;
  if (
    typeof searchResult.material === "object" &&
    !Array.isArray(searchResult.material) &&
    searchResult.material !== null
  ) {
    return searchResult.material.material || "";
  }
  return "";
};

const extractGrade = (searchResult: any): string => {
  if (!!searchResult.grade && typeof searchResult.grade === "string")
    return searchResult.grade;
  if (!searchResult.material) return "";
  if (
    typeof searchResult.material === "object" &&
    !Array.isArray(searchResult.material) &&
    searchResult.material !== null
  ) {
    return searchResult.material.grade || "";
  }
  return "";
};

const computeRerankKey = (searchResult: any, structureResults: any) => {
  const result = [];

  if (
    !!structureResults.material &&
    extractMaterial(searchResult)
      .toLowerCase()
      .includes(structureResults.material.toLowerCase())
  ) {
    result.push(-1);
  } else {
    result.push(0);
  }

  if (
    !!structureResults.alloy &&
    extractGrade(searchResult)
      .toLowerCase()
      .includes(structureResults.alloy.toLowerCase())
  ) {
    result.push(-1);
  } else {
    result.push(0);
  }

  if (
    !!structureResults.shape &&
    (searchResult.shape || "")
      .toLowerCase()
      .includes(structureResults.shape.toLowerCase())
  ) {
    result.push(-1);
  } else {
    result.push(0);
  }

  // dimensions
  const searchResDims = extractDimensions(searchResult);
  const queryDims = extractDimensions(structureResults);

  const dimScore = (dim: string): number => {
    if (
      dim in searchResDims &&
      normalizeDimensionToMm(searchResDims[dim]) >=
        normalizeDimensionToMm(queryDims[dim])
    )
      return 1;
    return 0;
  };

  result.push(-sum(Object.keys(queryDims).map(dimScore)));

  console.log(`Rerank key for ${searchResult.product}: ${result}`);
  return result;
};

const extractDimensions = (searchResult: any): any => {
  let result: { [key: string]: any } = {};

  for (const key of [
    "diameter",
    "length",
    "width",
    "thickness",
    "height",
    "hex",
    "wall",
  ]) {
    if (!!searchResult[key]) {
      result[key] = extractValueAndUnit(searchResult[key]);
    }
  }

  for (const key of ["dimension", "dimensions", "size"]) {
    if (!!searchResult[key]) {
      if (searchResult[key].constructor === Object) {
        result = {
          ...result,
          ...extractDimensions({
            shape: searchResult.shape,
            ...searchResult[key],
          }),
        };
      } else if (typeof searchResult[key] === "string") {
        result = {
          ...result,
          ...extractMultiValueAndUnit(searchResult[key], searchResult),
        };
      }
    }
  }

  return result;
};

const shapeIsRound = (shape: string) => {
  return (
    (shape && shape.includes("round")) ||
    shape === "bar" ||
    shape.includes("billet")
  );
};

const extractMultiValueAndUnit = (
  s: string,
  productOrQueryStructure: { shape: string },
): any => {
  let candidates = s
    .toLocaleLowerCase()
    .split(" x ")
    .map(extractValueAndUnit)
    .filter((candidate) => Object.keys(candidate).length > 0);

  // this whole thing is a mess. should solidify when bringing to backend.
  candidates = sortBy(candidates, [
    (c) => normalizeDimensionToMm({ unit: c.unit || "", value: c.value || 0 }),
  ]);

  const fallbackUnit = candidates
    .map((c) => c.unit)
    .filter((unit) => !!unit)
    .reduce((a, b) => a || b, undefined);

  const withFallbackUnit = (result: {
    [key: string]: { value?: number; unit?: string };
  }) => {
    return Object.fromEntries(
      Object.entries(result).map(([k, v]) => {
        return [k, { value: v.value, unit: v.unit || fallbackUnit }];
      }),
    );
  };

  let result = {};

  if (shapeIsRound(productOrQueryStructure.shape)) {
    // heuristic: if only one dimension is given it's usually the diameter
    if (candidates.length === 1) {
      result = {
        diameter: candidates[0],
      };
    } else if (candidates.length === 2) {
      const [diameter, length] = candidates;
      result = { diameter, length };
    }
  } else {
    if (candidates.length === 1) {
      // heuristic: if only one dimension is given it's usually the thickness
      result = {
        thickness: candidates[0],
      };
    } else if (candidates.length === 2) {
      const [thickness, length] = candidates;
      result = { thickness, length };
    } else if (candidates.length === 3) {
      const [thickness, width, length] = candidates;
      result = { thickness, width, length };
    }
  }
  return withFallbackUnit(result);
};

const extractValueAndUnit = (
  s: string | any,
): { value?: number; unit?: string } => {
  if (typeof s == "string") {
    const nums = s.match(/[0-9\.,]+/g);
    if (nums?.length !== 1) return {};
    s = s.replace(nums[0], "");
    let unit = undefined;
    if (s.includes("inch") || s.includes("in") || s.includes('"')) unit = "in";
    if (
      s.includes("feet") ||
      s.includes("foot") ||
      s.includes("ft") ||
      s.includes("'")
    )
      unit = "ft";
    for (let knownUnit of ["mm", "cm", "m"]) {
      if (s.includes(knownUnit)) {
        unit = knownUnit;
        break;
      }
    }

    return {
      value: parseFloat(nums[0].replace(",", "")),
      unit,
    };
  } else if (!!s.unit && !!s.value) {
    return s;
  } else {
    return {};
  }
};

export const getReranked = (searchResults: any[], structureResults: any) => {
  return sortBy(searchResults, [
    (searchResult) => computeRerankKey(searchResult, structureResults),
  ]);
};
