const resolveFullPath = (locationPathname: string, href: string) => {
  if (href.startsWith("/")) return href;
  const currPathComponents = locationPathname.split("/");
  currPathComponents[currPathComponents.length - 1] = href;
  return currPathComponents.join("/");
};

export function bestMenuMatchByUrl<T extends { href: string }>(
  locationPathname: string,
  items: T[],
): T | undefined {
  let currBest: T | undefined = undefined;
  let currBestFullPathLen = -1;
  for (let item of items) {
    const fp = resolveFullPath(locationPathname, item.href);
    if (!locationPathname.startsWith(fp)) continue;

    if (fp.length > currBestFullPathLen) {
      currBestFullPathLen = fp.length;
      currBest = item;
    }
  }

  return currBest;
}
