import { firebaseAuth } from "../../../firebase";
import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    signOut(firebaseAuth);
    navigate("/");
  }, []);

  return <div>Signing out...</div>;
}
