export const normalizeDimensionToMm = ({
  unit,
  value,
}: {
  unit: string;
  value: number;
}) => {
  const conversionFactors = {
    mm: 1,
    cm: 10,
    m: 1000,
    ft: 304.8,
    in: 25.4,
  };

  // heuristic: most of the dimensions of our current clients / scrape targets
  // are given in inches so we default to that.
  const factor =
    (conversionFactors as { [key: string]: number | undefined })[unit] ||
    conversionFactors.in;

  return value * factor;
};

export const convertDimensionUnits = (
  fromUnit: string,
  toUnit: string,
  value: number,
): number => {
  return (
    normalizeDimensionToMm({ value, unit: fromUnit }) *
    (1 / normalizeDimensionToMm({ value: 1, unit: toUnit }))
  );
};
