import { firebaseAuth } from "../firebase";

function responseOk(response: Response) {
  return response && response.status >= 200 && response.status < 300;
}

export type ApiRequestOptions = RequestInit & {
  jsonBody?: { [key: string]: any };
};

/**
 * Send an API request with the following processing added for convenience:
 *
 *  - Add commonly used headers to the request automatically
 *  - Stringify JSON
 *  - Throw an error if the response is not 2xx, so that you can avoid
 *    some error-handling code in your main promise result handler.
 */
export function sendRequest(
  url: string,
  requestOptions: ApiRequestOptions,
): Promise<Response> {
  // These headers apply to most requests so are added here for convenience.
  const standardHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  requestOptions.headers = { ...standardHeaders, ...requestOptions.headers };

  if (requestOptions.jsonBody) {
    requestOptions.body = JSON.stringify(requestOptions.jsonBody);
  }

  return fetch(url, requestOptions).then((response) => {
    if (!responseOk(response)) {
      // For now just concatenate the error into a string.
      // If more detail needed should adapt this function.
      throw new Error(`${response.status} ${response.text}`);
    }
    return response;
  });
}

/**
 * Send an API request that's authenticated with the current Firebase signed in user.
 *
 * Uses sendRequest() under the hood, so has the same opinions / convenience processing
 * as that function.
 */
export async function sendAuthenticatedRequest(
  url: string,
  requestOptions: ApiRequestOptions,
): Promise<Response> {
  const firebaseIdToken = await firebaseAuth.currentUser?.getIdToken();
  const authHeaders = {
    Authorization: "Bearer ".concat(firebaseIdToken || ""),
  };

  requestOptions.headers = { ...authHeaders, ...requestOptions.headers };

  return sendRequest(url, requestOptions);
}

export function classNames(...classes: (string | undefined | null)[]) {
  return classes.filter(Boolean).join(" ");
}

export function saveInputChangeInHookState(setStateFn: (v: any) => void) {
  return (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? (target as HTMLInputElement).checked
        : target.value;
    setStateFn(value);
  };
}
